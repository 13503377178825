<template>
  <div class="wasaga bg-light-blue">
    <div class="container">
      <b-row class="freight-big-pro-book large-text">
        <b-col>
          <p class="text-blue">
            SET YOUR SIGHTS <br />
            ON NEW <br />
            ADVENTURES
          </p>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col cols="10" md="8" class="position-relative pt-md-4">
        <div class="freight-big-pro-book large-text wasaga-text-overlay"><p class="text-white">WASAGA</p></div>
        <img
          src="/img/wasaga.jpg"
          alt="Wasaga water-front"
          class="img-fluid"
        />
      </b-col>
      <b-col cols="12" md="4" class="center-col">
        <p class="text-blue acumin-pro-wide-light sub-text m-0 max-w-250 wasaga-sub-text">
          Home to the longest fresh water beach in the world, miles of white
          sand, thrilling attractions and picture-perfect wonders. Complete with
          every family convenience, where all of Wasaga's best kept secrets are
          designed for life’s best adventures.
        </p>
      </b-col>
    </b-row>
    <b-container>
      <b-row class="pt-5">
        <b-col cols="12" md="5"
          class="d-md-flex pb-4 align-items-center justify-content-end text-blue acumin-pro-wide-medium sub-text"
          >A PLACE FOR FAMILY <br />
          TO FLOURISH <br />
          AND THRIVE.</b-col
        >
        <b-col cols="12" md="7" class="d-flex">
          <object
            data="/img/wasaga/wasahaga_go_karts.svg"
            type="image/svg+xml"
            class="seal"
          ></object>
          <object
            data="/img/wasaga/skull_island.svg"
            type="image/svg+xml"
            class="seal"
          ></object>
          <object
            data="/img/wasaga/white_sand_beach.svg"
            type="image/svg+xml"
            class="seal"
          ></object>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.refresh();
    setTimeout(() => {
    }, 500);
  },
  methods: {
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/variables";
.wasaga {
  padding: 100px 0px 0px 0px;
  .seal {
    width: 120px;
    @media screen and (min-width: 1500px) {
      width: 140px;
    }
    @media screen and (min-width: 2500px) {
      width: 140px;
    }
  }
  .wasaga-text-overlay {
    position: absolute;
    right: 14px;
    top: -10px;
  }
  .wasaga-sub-text {
    @media screen and (max-width: 1199px) {
      max-width: 180px;
    }
    @media screen and (max-width: 900px) {
      max-width: 350px;
    }
  }
  .center-col {
    display: flex;
    justify-content: center;
    padding-top: 40px;
    @media screen and (min-width: 1200px) {
      align-items: end;
      justify-content: start;
    }
  }
}
</style>
